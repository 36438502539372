<template v-cloak>
  <div>
    <YearlySwitchAlert ref="alertRef" />

    <div class="relative" style="height: 3.75rem">
      <div class="half-bg bg-white"></div>
      <div class="half-bg bg-repeat-x bg-seperator-start gray-bg"></div>
      <div class="billing-switch-container">
        <BillingSwitch
          :model-value="billingInterval"
          @update:model-value="updateBillingInterval"
          :options="billingIntervalOptions"
        />
      </div>
    </div>
    <div class="gray-bg">
      <section class="plans-section">
        <PaygProductSelect :products="products" :billing-interval="billingInterval">
          <template #plan-description>
            <PlanParagraph><span v-html="I18n.t('pricing.payg.description_html')"></span></PlanParagraph>
          </template>
        </PaygProductSelect>
        <StandardPlan :plan="getPlan(40)" :current-plan="currentPlan" @buy="buyPlan" @alert="openAlert">
          <template #title>{{ I18n.t("pricing.lite.title") }}</template>
          <template #subtitle
            ><span class="text-typo-secondary" v-html="I18n.t('pricing.use_up_to_x_credits_html', { x: 40 })"></span
          ></template>
          <template v-if="billingInterval === 'yearly'" #billing-appendix>
            {{ I18n.t("pricing.billed_yearly", { x: getPlan(40)?.formattedYearlyPrice }) }}
          </template>

          <template #plan-description>
            <div>
              <PlanParagraph type="title">{{ I18n.t("pricing.plans_features.ai_photo_editor") }}</PlanParagraph>
              <PlanParagraph>{{ I18n.t("pricing.plans_features.remove_background") }}</PlanParagraph>
              <PlanParagraph>{{ I18n.t("pricing.plans_features.erase_and_restore") }}</PlanParagraph>
              <PlanParagraph>{{ I18n.t("pricing.plans_features.max_quality_exports") }}</PlanParagraph>
            </div>
            <div class="!mt-4">
              <PlanParagraph type="title">{{ I18n.t("pricing.plans_features.automation") }}</PlanParagraph>
              <PlanParagraph>{{ I18n.t("pricing.plans_features.api_and_integrations") }}</PlanParagraph>
            </div>
          </template>
        </StandardPlan>
        <StandardPlan :plan="getPlan(200)" :current-plan="currentPlan" @buy="buyPlan" @alert="openAlert" highlighted>
          <template #title>
            <div class="flex justify-between items-center">
              <span>{{ I18n.t("pricing.pro.title") }}</span>
              <Badge color="brand">{{ I18n.t("pricing.most_popular") }}</Badge>
            </div>
          </template>
          <template v-if="billingInterval === 'yearly'" #billing-appendix>
            {{ I18n.t("pricing.billed_yearly", { x: getPlan(200)?.formattedYearlyPrice }) }}
          </template>
          <template #subtitle
            ><span class="text-typo-secondary" v-html="I18n.t('pricing.use_up_to_x_credits_html', { x: 200 })"></span
          ></template>
          <template #plan-description>
            <div>
              <PlanParagraph type="title">{{ I18n.t("pricing.plans_features.ai_photo_editor") }}</PlanParagraph>
              <PlanParagraph>{{ I18n.t("pricing.plans_features.remove_background") }}</PlanParagraph>
              <PlanParagraph>{{ I18n.t("pricing.plans_features.erase_and_restore") }}</PlanParagraph>
              <PlanParagraph>{{ I18n.t("pricing.plans_features.max_quality_exports") }}</PlanParagraph>
            </div>
            <div class="!mt-4">
              <PlanParagraph type="title">{{ I18n.t("pricing.plans_features.automation") }}</PlanParagraph>
              <PlanParagraph>{{ I18n.t("pricing.plans_features.api_and_integrations") }}</PlanParagraph>
              <PlanParagraph highlight>{{ I18n.t("pricing.plans_features.bulk_editing") }}</PlanParagraph>
            </div>
          </template>
        </StandardPlan>
        <VolumeBasedPlan
          :plans="remainingPlans"
          :current-plan="currentPlan"
          :billing-interval="billingInterval"
          @buy="buyPlan"
          @alert="openAlert"
        >
          <template #title>{{ I18n.t("pricing.volume_plus.title") }}</template>
          <template #plan-description>
            <div>
              <p class="text-typo-tertiary font-bold text-sm">{{ I18n.t("pricing.everything_in_pro") }}</p>
            </div>
            <div>
              <PlanParagraph type="title">{{ I18n.t("pricing.plans_features.custom_volume") }}</PlanParagraph>
              <PlanParagraph>
                <span
                  v-html="I18n.t('pricing.plans_features.high_volume', { link: '/enterprise/contact' })"
                  class="link-in-description"
                />
              </PlanParagraph>
            </div>
            <div class="!mt-4">
              <PlanParagraph type="title" highlight>{{
                I18n.t("pricing.plans_features.api_custom_controls")
              }}</PlanParagraph>
              <PlanParagraph>{{ I18n.t("pricing.plans_features.crop_scale_and_position") }}</PlanParagraph>
            </div>
          </template>
        </VolumeBasedPlan>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject, onMounted, ref } from "vue";
import FreePlan from "./free_plan.vue";
import StandardPlan from "./standard_plan.vue";
import VolumeBasedPlan from "./volume_based_plan.vue";
import { BillingInterval, Plan, PlansData, ProductsData } from "./types";
import BillingSwitch from "./billing_switch.vue";
import PlanParagraph from "./plan_paragraph.vue";
import { Badge } from "prism";
import User from "@/modules/user";
import YearlySwitchAlert from "./yearly_switch_alert.vue";
import { capitalizeString } from "@/modules/utils";
import PaygProductSelect from "./payg_product_select.vue";

const excludedPlans = [40, 200];
const props = defineProps<{
  plansData: string;
  productsData: string;
}>();

const alertRef = ref(null);

const openAlert = () => {
  alertRef.value?.show();
};

// Billing interval logic
const parsedPlansData: ComputedRef<PlansData> = computed(() => JSON.parse(props.plansData));
const productsData = computed(() => JSON.parse(props.productsData) as ProductsData);
const products = computed(() => productsData.value.products);

onMounted(() => {
  // Autobuy plan
  const shouldAutobuyPlan = computed(() => parsedPlansData.value.shouldAutobuyPlan);
  const selectedPlanForAutobuy = computed(() => parsedPlansData.value.selectedPlanForAutobuy);
  if (shouldAutobuyPlan.value) {
    window.doPurchase(selectedPlanForAutobuy.value.pPlanId, "plan", selectedPlanForAutobuy.value.paddleCoupon);
  }

  // Autobuy product
  const shouldAutobuyProduct = computed(() => productsData.value.shouldAutobuyProduct);
  const selectedProductForAutobuy = computed(() => productsData.value.selectedProductForAutobuy);
  if (shouldAutobuyProduct.value) {
    window.doPurchase(selectedProductForAutobuy.value?.pProductId, "product");
  }
});

const billingInterval = ref<BillingInterval>(
  (() => {
    return parsedPlansData.value.currentPlan?.name === "Free"
      ? "yearly"
      : parsedPlansData.value.currentPlan?.billingInterval || "yearly";
  })()
);

const I18n = inject("I18n");

const updateBillingInterval = (value: BillingInterval) => {
  billingInterval.value = value;
};

const getPlan = (creditsVolume: number): Plan | undefined => {
  const plan = parsedPlansData.value.plansByCredits[creditsVolume] || undefined;
  return plan.find((plan) => plan.billingInterval === billingInterval.value);
};

const billingIntervalOptions = [
  { label: capitalizeString(I18n.t("pricing.billing_interval.monthly")), value: "monthly" },
  { label: capitalizeString(I18n.t("pricing.billing_interval.yearly")), value: "yearly" },
];

const remainingPlans = computed((): Plan[] => {
  const extractedPlansForCurrentBillingInterval = Object.entries(parsedPlansData.value.plansByCredits);
  const filteredPlans = extractedPlansForCurrentBillingInterval.filter(
    ([_, plans]) => !excludedPlans.includes(plans[0].monthlyCredits)
  );

  // Find the plan with the correct billing interval for each credit amount
  return filteredPlans
    .map(([_, plans]) => {
      const planWithCorrectBillingInterval = plans.find((plan) => plan.billingInterval === billingInterval.value);
      return planWithCorrectBillingInterval || plans[0];
    })
    .filter(Boolean);
});

const currentPlan = computed(() => {
  return parsedPlansData.value.currentPlan;
});

const buyPlan = (plan: Plan) => {
  const { pPlanId, paddleCoupon } = plan;
  window.doPurchase(pPlanId, "plan", paddleCoupon);
};
</script>

<style scoped lang="scss">
.plans-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;

  max-width: 1280px;
  margin: 0 auto;
  padding: 4rem 1.5rem;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.half-bg {
  height: 50%;
}

.gray-bg {
  background-color: #f1f3f4;
}

.billing-switch-container {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
</style>
<style lang="scss">
.link-in-description {
  & a {
    text-decoration: none !important;
    font-weight: 500;
  }
}
</style>
