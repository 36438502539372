<template>
  <PlanCard>
    <PlanContent>
      <template #title>{{ I18n.t("pricing.payg.title") }}</template>
      <template #subtitle>
        <div class="w-full pt-2">
          <SelectInput
            id="select"
            name="volume-based-plan"
            :label="I18n.t('pricing.select_label')"
            :options="productOptions"
            :model-value="selectedOption"
            @update:modelValue="updateSelectedOption"
            controlled
          />
        </div>
      </template>

      <template #price>
        {{ selectedProduct.formattedPrice }}
      </template>

      <template #action>
        <Button full-width @click="() => buyProduct(selectedProduct)" :aria-label="I18n.t('pricing.buy_now')">
          {{ I18n.t("pricing.buy_now") }}
        </Button>
      </template>
      <template v-if="isYearlyBillingInterval" #billing-appendix></template>

      <template #plan-description>
        <slot name="plan-description" />
      </template>
    </PlanContent>
  </PlanCard>
</template>

<script setup lang="ts">
import { computed, inject, ref } from "vue";
import { BillingInterval, Product, SelectOption } from "./types";
import PlanCard from "./plan_card.vue";
import PlanContent from "./plan_content.vue";
import { Button, SelectInput } from "prism";

const I18n = inject("I18n");

const props = defineProps<{
  products: Product[];
  billingInterval: BillingInterval;
}>();
const parseProductToOption = (product: Product): SelectOption => ({
  label: `${product.credits.toLocaleString(I18n.locale)} ${
    product.credits > 1 ? I18n.t("pricing.credits") : I18n.t("pricing.credit")
  }`,
  value: `${product.id}`,
});

const productOptions = computed(() => props.products.map(parseProductToOption));

const selectedOption = ref<SelectOption>(productOptions.value[0]);

const updateSelectedOption = (value: SelectOption) => {
  selectedOption.value = value;
};

const selectedProduct = computed(() => props.products.find((product) => product.id === selectedOption.value.value));
const isYearlyBillingInterval = computed(() => props.billingInterval === "yearly");

const buyProduct = (product: Product) => {
  window.doPurchase(product.pProductId, "product");
};
</script>
