<template>
  <Dropdown
    align="middle"
    :direction="props.isBottomBar ? 'top' : 'bottom'"
    :vertical-offset="props.isBottomBar && 8"
    :horizontal-offset="dropdownHorizontalOffset"
    :on-open="onMenuOpen"
    :on-close="() => emit('update:dropdownActive', false)"
    :disabled="props.isProcessing"
  >
    <!--    Download Icon mobile bottom bar-->
    <template v-if="props.isMobile && props.isBottomBar" #trigger>
      <ActionButton
        class="sm:!flex-col"
        tone="primary"
        :loading="props.isProcessing || props.isProcessingHdDownload || props.isProcessingPreviewDownload"
      >
        <template #icon>
          <ArrowDownIcon />
        </template>
        <template #title>
          <span class="text-3xs pt-1">{{ I18n.t("editor.download") }}</span>
        </template>
      </ActionButton>
    </template>

    <!--    Download Icon mobile header -->
    <template v-else-if="props.isMobile && !props.isBottomBar" #trigger="{ active }">
      <div v-if="props.isProcessing" class="download-button-placeholder">
        <ButtonPlaceholder></ButtonPlaceholder>
      </div>
      <Button
        v-else
        :active="active"
        :loading="isProcessingHdDownload || isProcessingPreviewDownload"
        loader-type="spinner"
      >
        <template #start v-if="!(isProcessingHdDownload || isProcessingPreviewDownload)"> <ArrowDownIcon /></template>
      </Button>
    </template>

    <!--    Download Button Desktop -->
    <template v-else #trigger="{ active }">
      <div v-if="props.isProcessing" class="download-button-placeholder">
        <ButtonPlaceholder></ButtonPlaceholder>
      </div>
      <Button
        v-else
        :active="active"
        :loading="isProcessingHdDownload || isProcessingPreviewDownload"
        loader-type="spinner"
        full-width
      >
        {{ I18n.t("image.download") }}
        <template #end> <ChevronDownIcon /> </template>
      </Button>
    </template>

    <template #content>
      <div class="p-2 gap-2 flex flex-column menu-wrapper">
        <DownloadMenuItem
          :title="I18n.t('image.preview')"
          :description="props.previewResolution"
          :is-loading="isProcessingPreviewDownload || props.isProcessing"
          :on-click="() => props.downloadPreview()"
        >
          <template #start>
            <EyeIcon />
          </template>
          <template #end>
            <Badge v-if="isProcessingPreviewDownload || props.isProcessing" tone="secondary">
              <div class="loading-animation-container">
                <Spinner />
              </div>
            </Badge>
            <Badge v-else tone="secondary">
              <span>{{ I18n.t("editor.download_menu.free") }} </span>
            </Badge>
          </template>
        </DownloadMenuItem>
        <DownloadMenuItem
          v-if="!isImageTooLargeForIos"
          :title="I18n.t('editor.download_menu.max_quality')"
          :description="hdDownloadDescription"
          :is-loading="isLoadingHd"
          :disabled="!props.hdDownloadEnabled || isProcessing"
          :on-click="hdDownloadInfo.action"
        >
          <template #start>
            <LightningIcon />
          </template>
          <template #end v-if="props.hdDownloadEnabled">
            <Badge v-if="isLoadingHd" tone="secondary">
              <div class="loading-animation-container">
                <Spinner />
              </div>
            </Badge>
            <Badge v-else :tone="props.isHdImageProcessed ? 'secondary' : 'primary'">
              <span>{{ hdDownloadInfo.copyVariant }} </span>
            </Badge>
          </template>
        </DownloadMenuItem>
        <!-- Download button when ios limit is reached  -->
        <Dialog v-else-if="isImageTooLargeForIos" trigger-classes="w-full focus:outline-none" :dismissable="false">
          <template #trigger>
            <DownloadMenuItem
              :title="I18n.t('editor.download_menu.max_quality')"
              :description="props.hdResolution"
              :is-loading="isLoadingHd"
              :disabled="!props.hdDownloadEnabled"
              :on-click="hdDownloadInfo.action"
            >
              <template #start>
                <LightningIcon />
              </template>
              <template #end>
                <Badge v-if="isProcessingHdDownload || isLoadingCredits" tone="secondary">
                  <div class="loading-animation-container">
                    <Spinner />
                  </div>
                </Badge>
                <Badge v-else :tone="props.isHdImageProcessed ? 'secondary' : 'primary'">
                  <span>{{ hdDownloadInfo.copyVariant }} </span>
                </Badge>
              </template>
            </DownloadMenuItem>
          </template>
          <template v-slot:content="{ closeDialog }">
            <Ios16MPDialogContent :close-dialog="closeDialog" />
          </template>
        </Dialog>
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import "@/src/i18n";

import { useCredits } from "@/composables/credits";

import {
  ButtonPlaceholder,
  Button,
  ChevronDownIcon,
  Dropdown,
  LightningIcon,
  EyeIcon,
  Badge,
  ActionButton,
  ArrowDownIcon,
  Spinner,
} from "prism";
import { computed, inject } from "vue";

import DownloadMenuItem from "./download_menu_item.vue";
import { useCheckIfIOSImageTooLarge } from "@/composables/check_ios_image_too_large";
import User from "@/modules/user";

interface DownloadMenuProps {
  isProcessing: boolean;
  isProcessingPreviewDownload: boolean;
  isProcessingHdDownload: boolean;
  isHdImageProcessed: boolean;
  isBottomBar?: boolean;
  isMobile?: boolean;
  previewResolution?: string;
  hdResolution?: string;
  hdDownloadEnabled?: boolean;
  downloadPreview: (force_share?: boolean) => Promise<void>;
  downloadHd: (closeDialog?: () => void) => Promise<void>;
}

const emit = defineEmits<{
  (e: "update:dropdownActive", value: boolean): void;
}>();

const {
  credits,
  isLoading: isLoadingCredits,
  fetchCredits,
  DOWNLOAD_HD_CREDIT_COST,
  hasFetchCreditsErrored,
} = useCredits();
const isImageTooLargeForIos = useCheckIfIOSImageTooLarge();

const I18n = inject("I18n");

const props = defineProps<DownloadMenuProps>();

function onMenuOpen() {
  emit("update:dropdownActive", true);

  fetchCredits();
}
const hdDownloadInfo = computed(() => {
  const hasCredits = credits.value >= DOWNLOAD_HD_CREDIT_COST;
  const isProcessed = props.isHdImageProcessed;

  if (isProcessed) {
    return { copyVariant: I18n.t("editor.download_menu.unlocked"), action: () => props.downloadHd(null) };
  } else if (hasCredits || (hasFetchCreditsErrored && User.creditsBalance() > 1)) {
    return { copyVariant: I18n.t("dashboard.earn_reward"), action: () => props.downloadHd(null) };
  }

  return {
    copyVariant: I18n.t("editor.download_menu.unlock"),
    action: () => (document.location.href = "pricing?utm_source=download-hd-image"),
  };
});

const dropdownHorizontalOffset = computed(() => {
  if (props.isMobile && !props.isBottomBar) {
    return -8;
  } else if (props.isMobile && props.isBottomBar) {
    return 8;
  }
  return 0;
});

const isLoadingHd = computed(() => {
  return props.isProcessingHdDownload || isLoadingCredits.value || props.isProcessing;
});

const hdDownloadDescription = computed(() => {
  if (props.hdDownloadEnabled) {
    return props.hdResolution;
  }
  return I18n.t("editor.image_too_small");
});
</script>

<style scoped lang="scss">
.menu-wrapper {
  min-width: 300px;
  width: 100%;
}

.loading-animation-container {
  scale: 0.6;
}

.download-button-placeholder {
  width: 9.5rem;

  @media (max-width: 768px) {
    width: 2.5rem;
  }
}
</style>
