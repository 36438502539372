<template>
  <section class="flex flex-col gap-6 items-center !p-6 gray-bg" v-if="!User.signedIn()">
    <Heading level="h3" class="text-center"> <span v-html="I18n.t('pricing.free.subtitle_html')"></span></Heading>
    <div class="payg-section w-full rounded-4xl bg-white p-8 my-4">
      <h3 class="text-2xl !pb-2 font-body">{{ I18n.t("pricing.free.plan_feature_title") }}</h3>
      <p class="text-typo-secondary">{{ I18n.t("pricing.free.plan_feature_description") }}</p>
      <p class="text-typo-secondary">
        <span>
          {{ I18n.t("templates.already_have_an_account") }}
          <a :href="loginUrl">{{ I18n.t("navigation.login") }}</a>
        </span>
      </p>

      <Button variant="secondary" as="link" :href="signupUrl" :aria-label="I18n.t('pricing.free.action')">
        {{ I18n.t("pricing.free.action") }}
      </Button>
    </div>
  </section>
  <div class="gray-bg-separator gray-bg w-full bg-seperator-end bg-repeat-x"></div>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { Heading, Button } from "prism";
import User from "@/modules/user";

const I18n = inject("I18n");

defineProps({
  loginUrl: String,
  signupUrl: String,
});
</script>

<style scoped lang="scss">
.payg-section {
  max-width: 48rem;
}

.gray-bg {
  background-color: #f1f3f4;
}

.gray-bg-separator {
  height: 1.5rem;
}
</style>
